import React, { useState, useEffect } from "react";
import Loading from "./container/loader/loadingpage";
import { Footer, Navbar } from "./components";
import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";
import { cam1, cam2, cam3, cam4, cam6, dg } from "./constants/images";
import { FaCheck } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";

import DOMPurify from 'dompurify';

function SliderHome() {
  const { t } = useTranslation();

  return (
    <section>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={"fade"}
        breakpoints={""}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="mySwiper w-full h-full"
      >
        <SwiperSlide className="">
          <div className="image relative">
            <img
              src={cam4}
              className="h-screen lg:w-screen object-cover w-auto"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="tilte-content  bg-[hsla(0,0%,100%,0.55)] px-6 py-12 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14 backdrop-blur-[30px]] absolute top-[25%] space-y-5  flex justify-center lg:w-[700px] lg:items-start lg:mx-8  flex-col w-auto mx-2 "
            >
              <h3 className="lg:text-3xl text-xl dark:text-white  font-bold  text-left  ">
                Votre Sécurité, Notre Priorité, Agents de Sécurité Hautement
                Qualifiés
              </h3>
              <h2 className="text-md text-center dark:text-white  md:text-lg  font-light md:text-left ">
                Nos agents de sécurité privée, formés et expérimentés, sont
                dévoués à assurer votre tranquillité d'esprit. Choisir
                l'excellence pour protéger ce qui compte le plus.
              </h2>

              
              <Link to="/Services">  
                <button className=" w-auto px-6 h-[50px] bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300  ">
                  Plus d'information
                </button>
              </Link>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div className="image relative">
            <img
              src={cam4}
              className="h-screen lg:w-screen object-cover w-auto"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="tilte-content  bg-[hsla(0,0%,100%,0.55)] px-6 py-12 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14 backdrop-blur-[30px]] absolute top-[25%] space-y-5  flex justify-center lg:w-[700px] lg:items-start lg:mx-8  flex-col w-auto mx-2 "
            >
              <h3 className="lg:text-3xl text-xl dark:text-white font-bold   text-left ">
                Sécurité Exceptionnelle, Professionnels de la Sécurité pour Tous
                Vos Événements.
              </h3>
              <h2 className="text-md text-center dark:text-white  md:text-lg text-light md:text-left">
                De grands rassemblements aux événements exclusifs, nos agents
                événementiels veillent à ce que chaque moment soit sécurisé,
                permettant à votre public de profiter en toute sérénité.
              </h2>

              <Link to="/Services">
                <button className=" w-auto px-6 h-[50px] bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300  ">
                  Plus d'information
                </button>
              </Link>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div className="image relative">
            <img
              src={cam4}
              className="h-screen lg:w-screen object-cover w-auto"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="tilte-content  bg-[hsla(0,0%,100%,0.55)] px-6 py-12 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14 backdrop-blur-[30px]] absolute top-[25%] space-y-5  flex justify-center lg:w-[700px] lg:items-start lg:mx-8  flex-col w-auto mx-2 "
            >
              <h3 className="lg:text-3xl text-xl dark:text-white font-bold  text-left ">
                Sécurité Électronique Avancée, Protection Intelligente pour un
                Monde Connecté
              </h3>
              <h2 className="text-md text-center dark:text-white  md:text-lg  font-light md:text-left">
                Entrez dans l'ère de la sécurité intelligente avec nos solutions
                électroniques de pointe. Des systèmes de surveillance
                sophistiqués aux technologies d'accès sécurisé, nous offrons une
                protection adaptée à l'ère numérique.
              </h2>

              <Link to="/Services">
                <button className=" w-auto px-6 h-[50px] bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300  ">
                  Plus d'information
                </button>
              </Link>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="">
          <div className="image relative">
            <img
              src={cam4}
              className="h-screen lg:w-screen object-cover w-auto"
              alt=""
            />
            <div
              data-aos="fade-left"
              className="tilte-content  bg-[hsla(0,0%,100%,0.55)] px-6 py-12 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-[hsla(0,0%,5%,0.55)] dark:shadow-black/20 md:px-12 lg:-mr-14 backdrop-blur-[30px]] absolute top-[25%] space-y-5  flex justify-center lg:w-[700px] lg:items-start lg:mx-8  flex-col w-auto mx-2 "
            >
              <h3 className=" font-bold text-left lg:text-3xl text-xl dark:text-white ">
                Efficacité Éprouvée, Expertise Inégalée. Confiez votre sécurité
                à des professionnels chevronnés.
              </h3>
              <h2 className="text-md text-center dark:text-white  md:text-lg  font-light md:text-left">
                Chez Radiant Security, notre engagement envers l'excellence se
                traduit par une efficacité prouvée et une expertise inégalée.
                Nous sommes fiers de notre équipe hautement qualifiée, prête à
                relever tous les défis pour assurer votre sécurité.
              </h2>
              <Link to="/Services" >
                <button className=" w-auto px-6 h-[50px] bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300  ">
                Plus d'information
                </button>
              </Link>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

function BannerHome() {
  return (
    <>
      <section>
        <div
          data-aos="fade-right"
          className="h-[200px] xl:px-[150px] flex justify-around items-center bg-[#FF9900]  lg:px-8"
        >
          <div className="w-[800px] max-sm:hidden dark:text-white">
            <h2 className="text-2xl  ">
              Protégez-vous avec confiance. Notre engagement envers l'excellence
              en matière de sécurité garantit une protection robuste et efficace
              à chaque instant.{" "}
              <span className="font-bold uppercase">Y'a R on gère.</span>
            </h2>
          </div>
          <div className="space-x-8 flex gap-2   ">
            <Link to="/Services">
            <button className="px-6 py-4 bg-[#111047] text-[#FF9900] hover:bg-[#25244e] uppercase font-semibold transition ease-in-out duration-300 ">
              Nos services
            </button>
            </Link>
            <Link to="/About">
              <button className="px-6 py-4 bg-[#FF9900] text-[#111047]  ring-2 ring-[#111047] uppercase font-semibold hover:text-[#FF9900] hover:bg-[#111047] transition ease-in-out duration-300">
                Nous contacter
              </button>
            </Link>
            
          </div>
        </div>
      </section>
    </>
  );
}

function HeroHome() {
  return (
    <>
      <section className="py-24 lg:py-0 dark:bg-neutral-600">
        <div className="px-14 py-32 flex justify-around gap-x-4 lg:px-32 max-sm:py-0 max-lg:flex-col  ">
          <div
            data-aos="fade-up"
            className="w-[400px] h-[500px] max-lg:w-full "
          >
            <img src={cam3} alt="" className="w-screen h-full object-cover" />
          </div>
          <div
            data-aos="fade-down"
            className="w-[50%] flex flex-col justify-center gap-4 items-start max-lg:w-full max-lg:text-lg"
          >
            <h3 className="text-[#64748b] uppercase text-md dark:text-white">
              Ce que nous faisons
            </h3>
            <h2 className="text-2xl md:text-3xl lg:text-5xl dark:text-white">
              Nous sommes bien plus qu'une entreprise de sécurité.
            </h2>
            <p className=" text-justify dark:text-white">
              Chez <span className="font-bold text-firstColor text-lg uppercase">Radiant Assistance security</span>
              , nous sommes les gardiens de votre tranquillité d'esprit. Grâce à
              une équipe d'experts dédiés, nous offrons des solutions de
              sécurité personnalisées, alliant technologie de pointe et
              vigilance inébranlable. Votre sécurité, notre priorité absolue.
            </p>
            <Link to="/About">
              <button className="px-8 py-4 bg-[#FF9900] text-[#111047] dark:text-white font-semibold hover:bg-[#ffc164] transition ease-in-out duration-300">
                Apprendre plus à notre sujet
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

function ServicesHome() {
  return (
    <>
      <section className="bg-[#e0eafb] dark:bg-neutral-600 py-12">
        <div className="px-14 py-4 flex flex-col md:grid md:grid-cols-2 gap-4 h-auto max-sm:grid-cols-1 lg:px-24 lg:grid-cols-4 lg:py-12 ">
          <div
            data-aos="fade-up-right"
            className="flex flex-col gap-2 pb-8 col-span-2 justify-around max-sm:flex dark:text-white "
          >
            <h3 className="text-[#64748b] dark:text-white uppercase text-md ">
              offres et services
            </h3>
            <h2 className="text-2xl md:text-3xl lg:text-5xl w-[90%] max-sm:text-[2xl] dark:text-white">
              Nous sécurisons
              <span className="font-bold dark:text-white"> les biens et les personnes.</span>
            </h2>
            <p className=" text-justify dark:text-white">
              Nos services de sécurité vont au-delà de la simple surveillance.
              Ils s’adaptent à vos besoins et vous offre des solutions sur
              mesure. Nos experts en sécurité privée passeront au peigne fin
              votre environnement afin de vous offrir un écosystème unique et
              complet, qui vous communiquera un sentiment de confiance. Choisit
              RADIANT SECURITY, c’est choisir de construire et de protéger
              un avenir RADIEUX.
            </p>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center col-auto space-y-2 px-8 bg-white justify-center  hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4  max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-xl">
              Sécurité privée
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]">Agent de sécurité bronze</li>
              <li className="text-[#111047]">Agent de sécurité silver</li>
              <li className="text-[#111047]">Agent de sécurité gold</li>
            </ul>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center col-auto space-y-2 px-8 bg-white justify-center hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4  max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-xl">
              Sécurité spécialisé
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]    ">Agent de Sécurité Incendie</li>
              <li className="text-[#111047]    ">
                Agent de Sécurité Cynophile
              </li>
              <li className="text-[#111047]    ">
                Agent de Sécurité Événementiel
              </li>
            </ul>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center space-y-2 px-8 bg-white justify-center hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4 h-[360px] max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-xl">
              Sécurité Électronique
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]    ">Systèmes de Surveillance</li>
              <li className="text-[#111047]    ">Contrôle d'accès</li>
              <li className="text-[#111047]    ">Sécurité informatique</li>
            </ul>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center space-y-2 px-8 bg-white justify-center hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4  max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-xl">
              Offres Entreprise
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]    ">Evluation de la sécurité </li>
              <li className="text-[#111047]    ">Solutions personnalisées</li>
              <li className="text-[#111047]    ">Consultation en sécurité</li>
            </ul>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center space-y-2 px-8 bg-white justify-center hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4  max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-xl">
              Offres Résidentielles
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]    ">Sécurité domicilaire</li>
              <li className="text-[#111047]    ">Surveillance 24/7</li>
              <li className="text-[#111047]    ">Consultation personnalisée</li>
            </ul>
          </div>
          <div
            data-aos="fade-up-right"
            className="group flex flex-col text-center space-y-2 px-8 bg-white justify-center hover:bg-[#FF9900] transition ease-in-out duration-300  max-sm:mx-8  max-sm:py-4  max-sm:items-center"
          >
            <MdSecurity
              style={{ fontSize: 80 }}
              className="my-4 text-[#FF9900] group-hover:text-[#111047]  transition ease-in-out duration-300"
            />
            <h2 className=" capitalize font-bold text-[#111047] text-2xl">
              Offre Domotique
            </h2>
            <ul className="text-left flex flex-col justify-center list-disc text-sm">
              <li className="text-[#111047]    ">Professionalisme</li>
              <li className="text-[#111047]    ">Intégrité</li>
              <li className="text-[#111047]    ">Technologie de pointe</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

function FeaturesHome() {
  return (
    <>
      <section className=" pb-24 max-xl:h-auto dark:bg-neutral-600">
        <div className="gap-4 px-8 py-8 lg:py-24 flex flex-col relative lg:px-32 ">
          <div className="flex flex-col gap-y-6 w-[90%]">
            <h3 className="text-[#64748b] dark:text-white text-[12px] md:text-[20px] lg:text-md uppercase">
              Pourquoi nous choisir ?{" "}
            </h3>
            <h2 className=" text-2xl md:text-3xl dark:text-white lg:text-5xl w-[60%] capitalize">
              {" "}
              <span className="font-bold">Radiant Security</span> Protège Ce Que
              Vous Chérissez{" "}
            </h2>
          </div>
          <div className=" flex gap-14 justify-around items-start max-xl:flex-col">
            <img src={cam6} alt="" className="w-[700px] max-xl:w-full" />
            <p
              data-aos="fade-right"
              className="mt-8 text-black text-justify dark:text-white"
            >
              Chez Radiant Security, nous adoptons une approche proactive pour
              garantir la sécurité de nos clients. En combinant des agents
              hautement qualifiés, des technologies de pointe et une analyse
              approfondie des risques, nous créons des stratégies de sécurité
              sur mesure. Notre engagement envers l'efficacité opérationnelle et
              la communication transparente garantit une protection continue,
              assurant ainsi la tranquillité d'esprit de nos clients. <br />{" "}
              <br />
              Par son professionnalisme inébranlable. Forts d'une équipe
              hautement qualifiée et d'une approche rigoureuse, nous assurons
              une prestation de services de sécurité exemplaire.
            </p>
            <div
              data-aos="fade-up"
              data-aos-duration="20'00"
              className="absolute -bottom-10 right-60 grid grid-cols-2 text-[10px] lg:text-xl place-content-center place-items-start w-[800px] px-6 py-12 shadow-2xl  bg-[#FF9900] gap-4 max-xl:relative max-sm:grid max-xl:right-0 max-sm:w-auto max-sm:bg-white "
            >
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Expertise Avancée
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Équipe Diversifiée
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Innovation Technologique
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Engagement envers l'Intégrité
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Adaptabilité aux Nouveaux Défis
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Approche Personnalisée
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Réactivité et Efficacité
              </div>
              <div className="font-semibold flex gap-4 text-[#111047] items-start">
                <FaCheck className="font-bold " />
                Transparence et Communication
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function StatsHome() {
  const stats = [
    { id: 1, name: "clients", value: "75" },
    { id: 2, name: "Agents certifiés", value: "212" },
    { id: 3, name: "Partenaires", value: "20" },
  ];

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div data-aos="zoom-in" className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="mx-auto flex max-w-xs flex-col gap-y-4"
              >
                <dt className="text-base uppercase leading-7 text-gray-600">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
}

function TestimHome() {
  return (
    <>
      <section className="relative isolate overflow-hidden bg-white px-6 sm:py-12 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-[#ff9900ba] shadow-xl shadow-[#FF9900]/10 ring-1 ring-[#FF9900] sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <figure className="mt-10">
            <blockquote
              data-aos="fade-up"
              data-aos-duration="500"
              className="text-center text-xl font-semibold leading-8 text-[#111047] sm:text-xl sm:leading-9"
            >
              <p>
                “Fort de plusieurs années d’expériences dans le domaine de la sécurité privée, mon équipe et moi, nous inscrivons dans un dynamisme constant de révolution de la sécurité privée. Nous souhaitons être porteur d’une approche nouvelle, moderne et innovante en matière de sécurité. Nous vous demandons de nous faire confiance pour qu’on ensemble nous soyons bâtisseur de lendemain sûr et Radieux.”
              </p>
            </blockquote>
            <figcaption className="mt-10">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                className="mx-auto object-cover h-32 w-32 rounded-full"
                src={dg}
                alt=""
              />
              <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="font-semibold text-gray-900"
                >
                  Abdul Aziz <span className="font-bold uppercase">kone</span> 
                </div>
                <svg
                  viewBox="0 0 2 2"
                  width={3}
                  height={3}
                  aria-hidden="true"
                  className="fill-gray-900"
                >
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  className="text-gray-600"
                >
                  Directeur Général
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    </>
  );
}
const Comment = ({ text }) => {
  // Utilisez DOMPurify pour nettoyer le contenu HTML
  const sanitizedHTML = DOMPurify.sanitize(text);

  return (
    <div>
      {/* Contenu nettoyé */}
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
};

const Home = () => {
 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Effectuez ici votre opération asynchrone (ex. chargement de données depuis un serveur)
    // Une fois l'opération terminée, mettez setLoading(false) pour masquer la page de chargement
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Simule une opération asynchrone de 2 secondes
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div > 
      <>
      <Comment />
        <Navbar />
        <SliderHome />
        <BannerHome />
        <HeroHome />
        <ServicesHome />
        <FeaturesHome />
        <StatsHome />
        <TestimHome />
        <Footer />
      </>
    </div>
  );
};

export default Home;
