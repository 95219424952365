import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "./components";
import { about, im1, im2, im3, im4, imageid, logo } from "./constants/images";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { GoPeople } from "react-icons/go";
import Aos from "aos";
import { MdBusiness } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";
import "aos/dist/aos.css";
import DOMPurify from 'dompurify';

function HeaderAbout() {
  return (
    <div className="flex items-center justify-center relative">
      <img
        src={im4}
        alt="about_header"
        className="object-cover  "
        srcset=""
      />
      <div
        data-aos="fade-up"
        class="absolute  h-screen flex flex-col items-center justify-center lg:py-24 px-6 text-center"
      >
        <h1 class="mt-2 mb-16 text-3xl dark:text-black py-12  font-bold tracking-tight md:text-6xl xl:text-7xl">
          Notre métier, notre innovation <br />
          <span class="text-firstColor"> pour une Sécurité redéfinie </span>
        </h1>
      </div>
    </div>
  );
}

function Section1() {
  return (
    <div>
      <div className="container my-24 mx-auto md:px-6">
        <div className="py-8" data-aos="fade-up">
          <h1 className=" text-2xl lg:text-4xl font-bold uppercase text-center dark:text-white">
            Qui sommes nous ?
          </h1>
        </div>

        <section className="mb-0 lg:mb-32">
          <div className="flex items-center justify-content flex-wrap">
            <div
              data-aos="fade-left"
              className="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12"
            >
              <div className="flex lg:py-12">
                <img
                  src={im3}
                  className="z-[10] w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px]"
                  alt="image"
                />
              </div>
            </div>

            <div
              data-aos="fade-right"
              className="w-full shrink-0 grow-0 basis-auto lg:w-7/12"
            >
              <div className="flex h-full items-center rounded-lg bg-[#FF9900] p-6 text-center text-white lg:pl-12 lg:text-left">
                <div className="lg:pl-12 px-8 ">
                  <h2 className="mb-8 text-2xl lg:text-4xl font-bold">Notre histoire</h2>
                  <p className="mb-8 pb-2 lg:pb-0 text-justify">
                    Radiant Assistance Security, fondée le 7 juin 2021, est une entreprise qui symbolise l'innovation et l'excellence dans le domaine de la sécurité privée en Côte d'Ivoire. Née d'une vision ambitieuse, elle s'est rapidement distinguée par son approche novatrice, son engagement indéfectible envers la qualité et son efficacité remarquable. <br />
                    <br />
                    Dans une dynamique de croissance et d'évolution, l'entreprise a opéré un changement significatif pour redynamiser sa strategie, reflétant son désir de se rapprocher encore plus de ses clients et de leurs aspirations à une vie sereine et sécurisée. Cette transition marque un tournant stratégique dans son histoire, symbolisant un renouveau et une adaptation continue aux besoins changeants de sa clientèle.
                    <br />
                    Avec une équipe jeune, dynamique et technologiquement avertie, Radiant Assistance Security a su relever les défis sécuritaires contemporains. Elle a intégré des solutions digitales avancées, restant ainsi à l'avant-garde technologique dans son secteur. Aujourd'hui, malgré un marché hautement concurrentiel, l'entreprise se distingue comme l'un des acteurs les plus crédibles et modernes de la sécurité en Côte d'Ivoire, avec des ambitions de s'étendre à travers la sous-région.
                  </p>

                  <div class="mx-auto mb-8 flex flex-col md:flex-col md:justify-around xl:justify-start">
                    <p class="mx-auto mb-4 flex items-center  md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="mr-2 h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Protection et Performance
                    </p>
                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="mr-2 h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Réactivité et Respect
                    </p>
                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="mr-2 h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Intégrité et Ingéniosité
                    </p>
                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="mr-2 h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Sécurité et Solidarité
                    </p>
                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="mr-2 h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Magnanime et Maitrise de Soi
                    </p>
                  </div>

                  <p className="text-justify">
                    Entièrement digitalisée et équipée pour affronter les défis actuels, Radiant Assistance Security offre des services de haute qualité à des tarifs compétitifs, se démarquant sur le marché. Cette entreprise est l'incarnation de la détermination, de l'innovation et de l'engagement à contribuer activement à la vie radieuse et sécurisée que chaque client mérite.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

function Banner1() {
  return (
    <div>
      <div class="container my-24 mx-auto md:px-6" data-aos="zoom-in">
        <section class="mb-32 text-center md:text-left">
          <div class="flex flex-wrap justify-center">
            <div class="w-full shrink-0 grow-0 basis-auto px-3 lg:w-10/12">
              <div class="grid items-center gap-x-6 md:grid-cols-2">
                <div class="mb-6 md:mb-0">
                  <h2 class="text-sm lg:text-3xl font-bold">
                    Rejoignez-nous pour rester connectés avec l'innovation, la
                    sécurité et l'engagement
                    <br />
                    <span class="text-firstColor dark:text-firstColor-400">
                      Suivez-nous sur les réseaux sociaux et participez à notre
                      communauté dédiée à un avenir plus sûr et sécurisé.
                    </span>
                  </h2>
                </div>

                <div class="mb-6 md:mb-0 md:ml-auto">
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#1877f2] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#1da1f2] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#ea4335] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#c13584] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#0077b5] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#e60023] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
function Team() {
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div
          class="mx-auto max-w-screen-md text-center mb-8 lg:mb-16"
          data-aos="fade-up"
        >
          <div className="py-8">
            <h1 className="text-4xl font-bold uppercase text-center">
              Notre Equipe
            </h1>
          </div>
          <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">
            Bien plus qu'une simple équipe, nous sommes une famille passionnée
            qui partage une vision commune : fournir des solutions de sécurité
            exceptionnelles avec un engagement indéfectible envers l'excellence.
            Chacun des membres de notre équipe apporte une expertise unique,
            contribuant à faire de{" "}
            <span className="uppercase text-2xl font-semibold text-firstColor">
              Radiant assistance security
            </span>{" "}
            un leader dans le domaine de la sécurité.
          </p>
        </div>
        <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
          <div
            data-aos="fade-left"
            class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700"
          >
            <a href="#">
              <img
                data-aos="zoom-in"
                class="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                alt="Bonnie Avatar"
              />
            </a>
            <div class="p-5">
              <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Bonnie Green</a>
              </h3>
              <span class="text-gray-500 dark:text-gray-400">
                CEO & Web Developer
              </span>
              <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                Bonnie drives the technical strategy of the flowbite platform
                and brand.
              </p>
              <ul class="flex space-x-4 sm:mt-0">
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            data-aos="fade-right"
            class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700"
          >
            <a href="#">
              <img
                data-aos="zoom-in"
                class="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                alt="Jese Avatar"
              />
            </a>
            <div class="p-5">
              <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Jese Leos</a>
              </h3>
              <span class="text-gray-500 dark:text-gray-400">CTO</span>
              <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                Jese drives the technical strategy of the flowbite platform and
                brand.
              </p>
              <ul class="flex space-x-4 sm:mt-0">
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            data-aos="fade-left"
            class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700"
          >
            <a href="#">
              <img
                data-aos="zoom-in"
                class="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png"
                alt="Michael Avatar"
              />
            </a>
            <div class="p-5">
              <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Michael Gough</a>
              </h3>
              <span class="text-gray-500 dark:text-gray-400">
                Senior Front-end Developer
              </span>
              <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                Michael drives the technical strategy of the flowbite platform
                and brand.
              </p>
              <ul class="flex space-x-4 sm:mt-0">
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            data-aos="zfade-right"
            class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700"
          >
            <a href="#">
              <img
                data-aos="zoom-in"
                class="w-full rounded-lg sm:rounded-none sm:rounded-l-lg"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/sofia-mcguire.png"
                alt="Sofia Avatar"
              />
            </a>
            <div class="p-5">
              <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href="#">Lana Byrd</a>
              </h3>
              <span class="text-gray-500 dark:text-gray-400">
                Marketing & Sale
              </span>
              <p class="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                Lana drives the technical strategy of the flowbite platform and
                brand.
              </p>
              <ul class="flex space-x-4 sm:mt-0">
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function OurWork() {
  return (
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="container my-8 mx-auto md:px-6">
        <section class="mb-32 text-center">
          <div className="pb-24" data-aos="fade-up">
            <h1 className="text-2xl lg:text-4xl text-firstColor font-bold uppercase text-center">
              Notre Engagement en Sécurité
            </h1>
          </div>
          <div class="grid gap-x-4 md:grid-cols-4 lg:gap-x-8 px-8">
            <div class="mb-12 md:mb-0" data-aos="zoom-out">
              <div class="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
              <AiOutlineSafetyCertificate className="h-10 w-10"/>
              </div>
              <h5 class="mb-4 text-lg font-bold">
                Engagement envers les Clients
              </h5>
              <p class="text-neutral-500 dark:text-neutral-300 text-justify">
                Nous nous engageons à fournir à nos clients une tranquillité
                d'esprit totale. Chaque aspect de nos services de sécurité est
                conçu pour répondre aux besoins uniques de nos clients, en
                mettant l'accent sur la prévention, la réactivité et la
                résolution efficace des situations potentiellement dangereuses.
              </p>
            </div>

            <div class="mb-12 md:mb-0" data-aos="zoom-out">
              <div class="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
              <GoPeople className="h-10 w-10" />
              </div>
              <h5 class="mb-4 text-lg font-bold">
                Investissement dans les Employés
              </h5>
              <p class="text-neutral-500 dark:text-neutral-300 text-justify">
                Notre équipe de professionnels de la sécurité est le cœur de
                notre entreprise. Nous nous engageons à offrir un environnement
                de travail qui favorise la formation continue, l'éthique
                professionnelle et le dévouement. La sécurité de nos clients
                commence par la qualité et la fiabilité de notre personnel.
              </p>
            </div>
            <div class="mb-12 md:mb-0" data-aos="zoom-out">
              <div class="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
              <MdBusiness className="h-10 w-10" />
              </div>
              <h5 class="mb-4 text-lg font-bold">
                Responsabilité Sociale et Environnementale
              </h5>
              <p class="text-neutral-500 dark:text-neutral-300 text-justify">
                En tant qu'entreprise de sécurité, nous avons une responsabilité
                particulière envers la communauté. Notre mission inclut la
                promotion de la sécurité publique, la collaboration avec les
                autorités locales et la contribution positive à la création d'un
                environnement sûr pour tous.
              </p>
            </div>
            <div class="mb-12 md:mb-0" data-aos="zoom-out">
              <div class="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
                <GrTechnology className="h-10 w-10" />
              </div>
              <h5 class="mb-4 text-lg font-bold">Innovation Continue</h5>
              <p class="text-neutral-500 dark:text-neutral-300 text-justify">
                Face à l'évolution constante des menaces, notre mission est de
                rester à l'avant-garde de la technologie et des meilleures
                pratiques en matière de sécurité. Nous investissons dans
                l'innovation pour anticiper les risques futurs et assurer la
                protection continue de nos clients.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
function ServicesSection() {
  return (
    <>
      <section>
        <div class="container md:px-6 py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="pb-24" data-aos="fade-up">
            <h1 className="text-2xl lg:text-4xl font-bold uppercase text-center dark:text-white">
              Nos services
            </h1>
          </div>
          <section class="mb-32" data-aos="fade-left">
            <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
              <div class="flex flex-wrap items-center">
                <div class="hidden shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                  <img
                    src={im1}
                    alt="Trendy Pants and Shoes"
                    class="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                  />
                </div>
                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                  <div class="px-6 py-12 md:px-12">
                    <h2 class="mb-4 text-2xl lg:text-3xl uppercase text-secondColor dark:text-firstColor font-bold">
                      Sécurité privée
                    </h2>
                    <p class="mb-6 text-neutral-500 dark:text-neutral-300 ">
                      La sécurité privée est bien plus qu'une simple profession
                      pour nous – c'est une vocation. Chez{" "}
                      <span className="text-2xl font-semibold text-[#ff9900]">
                        Radiant Assistance Sécurity
                      </span>
                      , nous nous engageons à protéger ce qui compte le plus
                      pour vous. Forts de notre expérience et de notre
                      dévouement, nous fournissons des solutions de sécurité
                      personnalisées, alliant innovation et professionnalisme.
                      Votre tranquillité d'esprit est notre objectif, et nous
                      sommes fiers de veiller sur vous, votre famille et vos
                      biens. Faites confiance à notre équipe dédiée de
                      professionnels de la sécurité pour un service fiable et de
                      qualité supérieure. Parce que votre sécurité mérite
                      l'excellence
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="mb-32" data-aos="fade-right">
            <div class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
              <div class="flex flex-wrap items-center">
                <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                  <div class="px-6 py-12 md:px-12">
                    <h2 class="mb-4 text-2xl lg:text-3xl uppercase text-secondColor font-bold dark:text-firstColor">
                      Sécurité Electronique
                    </h2>
                    <p class="mb-6 text-neutral-500 dark:text-neutral-300">
                      La sécurité électronique redéfinit les normes de
                      protection avec une approche moderne et intelligente. Chez{" "}
                      <span className="text-2xl font-semibold text-[#ff9900]">
                        Radiant Assistance Sécurity
                      </span>
                      , nous sommes à la pointe de l'innovation en matière de
                      sécurité électronique. Nos solutions intègrent des
                      technologies de pointe telles que la surveillance vidéo
                      intelligente, les systèmes d'alarme connectés et l'accès
                      sécurisé. Nous transformons les espaces en environnements
                      sûrs et intelligents, offrant une protection proactive
                      24h/24. Avec notre expertise, chaque détail est pris en
                      compte pour garantir une sécurité sans compromis.
                      Choisissez la tranquillité d'esprit, choisissez la
                      sécurité électronique évoluée avec nous.
                    </p>
                  </div>
                </div>
                <div class="hidden shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                  <img
                    src={im2}
                    alt="Trendy Pants and Shoes"
                    class="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

function Whyme(params) {
  return <></>;
}

function Noscontact(params) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [receiveCopy, setReceiveCopy] = useState(true);

     const handleChange = (e) => {
    // Mettez à jour l'état du formulaire à chaque changement de saisie
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'message':
        setMessage(e.target.value);
        break;
      case 'receiveCopy':
        setReceiveCopy(!receiveCopy); // Si vous voulez basculer la valeur du state, utilisez le contraire de sa valeur actuelle
        break;
      default:
        break;
    }
  };

    const handleSubmit = async () => {
        try {
          const response = await fetch('http://localhost:3001/submit-form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message,
                receiveCopy: receiveCopy,
            }),
          });
      
          const result = await response.json();
          console.log(result); // Facultatif : affichez la réponse du serveur dans la console
      
          // Ajoutez ici le code pour traiter la réponse du serveur (par exemple, afficher un message à l'utilisateur)
        } catch (error) {
          console.error('Erreur lors de la soumission du formulaire', error);
        }
      };
  return (
    <>
      <div class="container my-24 mx-auto md:px-6" data-aos="fade-down">
        <section class="mb-32 text-center" id="form" >
          <div class="py-12 md:px-12" id="formulaire">
            <div class="container mx-auto xl:px-32">
              <div class="grid items-center lg:grid-cols-2">
                <div class="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                  <div class="relative z-[1] block rounded-lg  bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[30px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                    <h2 class="mb-12 text-3xl font-bold">Nous contacter</h2>
                    <div class="px-6 py-4">
                            <h3 class="text-lg font-medium text-gray-900 dark:text-white">Adresse</h3>
                            <p class="mt-1 text-gray-600 dark:text-gray-200">Cocody, Abidjan, Côte d'Ivoire</p>
                        </div>
                        <div class="border-t border-gray-200 px-6 py-4">
                            <h3 class="text-lg font-medium text-gray-900 dark:text-white">Nos Heures</h3>
                            <p class="mt-1 text-gray-600 dark:text-gray-200">Lundi - Vendredi: 8h - 17h</p>
                            
                            
                        </div>
                        <div class="border-t border-gray-200 px-6 py-4 dark:text-white">
                            <h3 class="text-lg font-medium text-gray-900 dark:text-white">Contact</h3>
                            <p class="mt-1 text-gray-600 dark:text-white">Email: info@Radiant.ci</p>
                            <p class="mt-1 text-gray-600 dark:text-white">+225 0150 007 007</p>
                            <p class="mt-1 text-gray-600 dark:text-white">++225 0715 37 37 37</p>
                        </div>
                        
                  </div>
                </div>
                <div class="md:mb-12 lg:mb-0">
                  <div class="relative h-[700px] rounded-lg shadow-lg dark:shadow-black/20">
                    <iframe
                      src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      class="absolute left-0 top-0 h-full w-full rounded-lg"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const About = () => {

  const Comment = ({ text }) => {
    // Utilisez DOMPurify pour nettoyer le contenu HTML
    const sanitizedHTML = DOMPurify.sanitize(text);
  
    return (
      <div>
        {/* Contenu nettoyé */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    );
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
    <div > 
      <Comment />
      <Navbar />

      <HeaderAbout />
      <Section1 />

      <OurWork />
      <ServicesSection />
      <Noscontact />
      

      <Footer />
    </div>
    
    </>
  );
};

export default About;
