import React from 'react'
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import './footer.css';
import { Link } from 'react-router-dom';

import { logo, logo1, logo3 } from '../../constants/images';


function Banner1() {
  return (
    <div>
      <div class="container py-8 mx-auto md:px-6" data-aos="zoom-in">
        <section class=" text-center md:text-left">
          <div class="flex flex-wrap justify-center">
            <div class="w-full shrink-0 grow-0 basis-auto px-3 lg:w-11/12">
              <div class="grid items-center gap-x-6 md:grid-cols-2">
                <div class="mb-6 md:mb-0">
                  <h2 class="text-sm lg:text-xl font-bold">
                    Rejoignez-nous pour rester connectés avec l'innovation, la
                    sécurité et l'engagement
                    <br />
                    <span class="text-firstColor dark:text-firstColor-400">
                      dédiée à un avenir plus sûr et sécurisé.
                    </span>
                  </h2>
                </div>

                <div class="mb-6 md:mb-0 md:ml-auto">
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#1877f2] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                      />
                    </svg>
                  </button>

                  

                 

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#c13584] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-[#0077b5] p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="h-4 w-4"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="mb-2 inline-block rounded-full bg-black p-3 text-xs hover:-translate-y-6 font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="16" 
                      height="16" 
                      fill="currentColor" 
                      class="bi bi-tiktok" 
                      viewBox="0 0 16 16"> 
                      <path 
                        d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"/> 
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}


const Footer = () => {
  return (
    <footer
      className="bg-[#111047] px-8 text-center text-[#FF9900] dark:bg-neutral-600 dark:text-neutral-200 lg:px-32 lg:text-left">
      <Banner1/>

      
      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4 place-content-center place-items-center">
      
          <div className="flex flex-col items-center text-center">
            <img src={logo3} className='h-14 md:h-22 flex items-center justify-center my-8 ' alt="" />
            <p>
             Nous sommes protecteur d'avenir <span className='uppercase font-bold '>radieux</span>
            </p>
          </div>
         
          <div className="py-8 h-[100%]">
            <h6
              className="mb-8 flex justify-center font-semibold text-xl uppercase md:justify-start">
              offres et Services
            </h6>
            <p className="mb-4">
              <a href="#!" className="text-[#FF9900] dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                >Sécurité Electronique</a>
            </p>
            <p className="mb-4">
              <a href="#!" className="text-[#FF9900] dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                >Sécurité privée Physique</a>
            </p>
            <p className="mb-4">
              <a href="https://Safer.ci" className="text-[#FF9900] dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                >SafeR Sécurité Résidentielle</a>
            </p>
          </div>
        
          <div className="py-8 h-[100%]">
            <h6
              className="mb-8 flex justify-center font-semibold text-xl uppercase md:justify-start">
              liens utiles
            </h6>
            <div>
             
                {/*<p className="mb-4">
                  <a href="#!" className="text-[#FF9900] dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                    > <Link to="/Products" target='_blank'>Produits</Link></a>
  </p>*/}
              
            
                <p className="mb-4">
                  <a href="#!" className="text-[#FF9900] dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                    >  <Link to="/Services" target='_blank'>Services</Link></a>
                </p>
              
             
                <p className="mb-4">
                  <a href="#!" className="text-[#FF9900] capitalize dark:text-neutral-200 transition ease-in-out hover:text-white duration-200"
                    > <Link to="/About" target='_blank'>à Propos</Link></a>
                </p>
              
              
            </div>
            
          </div>
          
          <div className='py-8'>
            <h6
              className="mb-10 text-xl flex justify-center font-semibold uppercase md:justify-start">
              Contact
            </h6>
            <div>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5">
                  <path
                    d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                  <path
                    d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
                Cocody, Abidjan ,Côte d'Ivoire
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 512 512" 
                  id="IconChangeColor" 
                  className="mr-3 h-5 w-5"
                  height="200" width="200">
                    <path d="M192 64h197.5L416 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C419.4 3.375 411.2 0 402.8 0H160C142.3 0 128 14.33 128 32v128h64V64zM64 128H32C14.38 128 0 142.4 0 160v320c0 17.62 14.38 32 32 32h32c17.62 0 32-14.38 32-32V160C96 142.4 81.63 128 64 128zM480 192H128v288c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V224C512 206.4 497.6 192 480 192zM288 432c0 8.875-7.125 16-16 16h-32C231.1 448 224 440.9 224 432v-32C224 391.1 231.1 384 240 384h32c8.875 0 16 7.125 16 16V432zM288 304c0 8.875-7.125 16-16 16h-32C231.1 320 224 312.9 224 304v-32C224 263.1 231.1 256 240 256h32C280.9 256 288 263.1 288 272V304zM416 432c0 8.875-7.125 16-16 16h-32c-8.875 0-16-7.125-16-16v-32c0-8.875 7.125-16 16-16h32c8.875 0 16 7.125 16 16V432zM416 304c0 8.875-7.125 16-16 16h-32C359.1 320 352 312.9 352 304v-32C352 263.1 359.1 256 368 256h32C408.9 256 416 263.1 416 272V304z" id="mainIconPathAttribute" fill="#ff9900" filter="url(#shadow)" stroke="#f5f0f0">
                    </path><filter id="shadow"><feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black"></feDropShadow></filter><filter id="shadow"><feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black"></feDropShadow></filter></svg>
                08 BPM 408 Abidjan 08
              </p>
              
              <p className="mb-4 flex font-bold items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5">
                  <path
                    d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path
                    d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                info@Radiant.ci
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5">
                  <path
                    fill-rule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clip-rule="evenodd" />
                </svg>
                +225 0150 007 007
              </p>
              <p className="mb-4 flex items-center justify-center md:justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-5 w-5">
                  <path
                    fill-rule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clip-rule="evenodd" />
                </svg>
                +225 0715 37 37 37
              </p>
            </div>
          </div>
        </div>
      </div>

      
      <div className="p-6 text-center dark:bg-neutral-700">
        <span>© 2023 powered by</span>
        <Link to="https://kognitiv.ci">
          <a
          className="font-semibold text-[#FF9900]"
          > Kognitiv Technologies</a>
        </Link>
      </div>
      
    </footer>
  )
}

export default Footer