import React, { useState, useEffect } from 'react'



import './App.css';

import Loader from './container/loader/loadingpage'


import Home from './Home';
import Pages from './Pages'
import Contacts from './Contacts'
import Services from './Services';
import Login from './loginForm/Login';
import Clientlogin from './loginForm/Clientlogin';
import Personallogin from './loginForm/Personallogin';
import Register from './loginForm/Register';
import { Routes, Route } from 'react-router-dom';
import { Footer, Navbar } from './components';
import About from './About';
import ServiceE from './ServiceE';
import ServicesP from './ServicesP';
import Products from './Products';

// connexion client
import { createClient } from '@supabase/supabase-js';
import { createClerk } from '@clerk/clerk-js';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// security


function PrimaryPage() {
  
  
  return(
    <div>
      
     
      <Routes>          
          <Route path='/' element={<Home />}/>
          <Route path='/Services' element={<Services />}/>
          <Route path='/loginForm/Login' element={<Login />}  />
          <Route path='/loginForm/Register' element={<Register/>}/> 
          <Route path='/Contacts' element={<Contacts/>}/> 
          <Route path='/About' element={<About/>}/> 
          <Route path='/About#formulaire' element={<About/>}/> 
          <Route path='/ServicesP' element={<ServicesP/>}/> 
          <Route path='/ServiceE' element={<ServiceE/>}/> 
          <Route path='/Products' element={<Products/>}/>
          

      </Routes>
     
    </div>
  )
  
}
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "About": "About",
          "Products": "Products",
          "Slider1h3":"we are always on guard foryor family and business",
          "Slider1h2":"Security service for your safe personality",


        },
      },
      fr: {
        translation: {
          "About": "à propos de nous",
          "Products": "Produits",
        // home page 
        // slider home 
          "Slider1h3":"Nous sommes toujours en alerte pour votre famille et votre entreprise",
          "Slider1h2":"Service de sécurité pour votre personnalité en toute sécurité."

        },
      },
      // Ajoutez d'autres langues au besoin
    },
    lng: 'en', // Langue par défaut
    fallbackLng: 'en', // Langue de secours si la langue demandée n'est pas disponible
    interpolation: {
      escapeValue: false, // Ne pas échapper les variables dans les traductions
    },
  });

const simulateDelay = () => {
  return new Promise((resolve) => {
     setTimeout(() => {
       resolve();
     }, 9000);
  });
}

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    simulateDelay().then(() => {
       setLoading(false);
    });
   }, []);

  return (
    <div>
      {loading ? (
        <Loader/>
      ) : (
      <PrimaryPage/>  
      )}
    </div>
  );
}


export default App;
