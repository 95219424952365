import React, { createContext, useState, useEffect } from 'react';
import { FaMoon } from "react-icons/fa6";

const ThemeContext = createContext();   

function DarkButton() {

    const [theme, setTheme] = useState(()=>
    {
        if(window.matchMedia("(prefers-color-scheme:dark)").matches){
            return "dark";
        }
        return "light";
    });

    useEffect(() => {
      if (theme === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }, [theme]);

  return (
    <div>
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <div >
                <button
                className={`bg-black flex items-center justify-center gap-x-4 hover:text-black hover:bg-white transition ease-in-out duration-300 ring-black ring-1 text-white px-4 py-2 rounded-3xl ${theme === "dark" ? "dark" : "light"}`}
                onClick={() => setTheme(prevTheme => prevTheme === "light" ? "dark" : "light")}
                >
                mode nuit  <FaMoon className='text-2xl' />
                </button>
            </div>
        </ThemeContext.Provider>
    </div>
  )
}

export default DarkButton