/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "./components";
import { cam6, im1, im2, im3, im5, im6, im8, product1, product10, product13, product2, product4, product8, video } from "./constants/images";
import { FaCheck } from "react-icons/fa";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { GiCalculator, GiBodyHeight, GiWeight, GiSkills } from "react-icons/gi";
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import "flowbite";
import "./App.css";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import DOMPurify from 'dompurify';

const Services = () => {

  const Comment = ({ text }) => {
    // Utilisez DOMPurify pour nettoyer le contenu HTML
    const sanitizedHTML = DOMPurify.sanitize(text);
  
    return (
      <div>
        {/* Contenu nettoyé */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    );
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div id="pagesService" >
      < Comment />
      <Navbar />
      <HeroSection />
      <BannersHero
        titre1="Votre partenaire de confiance en sécurité, "
        text1="Protégez vos biens et assurez la sécurité de vos événements avec notre équipe expérimentée"
        buttonText1="Nous rejoindre"
      />
      <ServP
        sousTitre1="Nos services en sécurité privéee physique"
        titre1=" la sécurité est une affaire sérieuse, avec "
        titre2="RADIANT ASSISTANCE SECURITY"
        image1={im6}
        image2={im5}
        image3={im8}
        imageTitle1="Agent Bronze"
        imageSousTitle1="Nous avons tout a vous offrir"
        imageTitle2="Agent Silver"
        imageSousTitle2="Nous avons tout a vous offrir"
        imageTitle3="Agent gold"
        imageSousTitle3="Nous avons tout a vous offrir"
      />
      <WhatWeDo
        sousTitre2="Qu'est ce que nous proposons ?"
        titre1="Une Expertise Séculaire "
        titre2=" Des Experts d'Exception"
        image2={im3}
        text1="  inégalée dans le domaine de la sécurité privée en Côte d'Ivoire, Radiant Assistance Security s'impose comme le choix par excellence pour la protection de vos biens et de vos proches. Notre équipe dynamique, composée d'experts chevronnés venant de la sous-région africaine et des Amériques, s'engage à assurer votre sécurité avec un professionnalisme inébranlable."
        text2="Nous croyons en l'importance de l'intégrité, de la volonté immuable d'agir et du professionnalisme. Chez Radiant Assistance Security, nous nous positionnons en tant qu'entreprise de sécurité moderne, entièrement digitalisée et prête à relever les nouveaux défis sécuritaires. Notre engagement envers la protection des personnes et des biens est la pierre angulaire de notre mission."
      />

      <ServE
        sousTitre1="Nos services en sécurité privéee"
        titre1=" la sécurité est une affaire sérieuse, avec "
        titre2="RADIANT ASSISTANCE SECURITY"
        image1={im6}
        image2={im5}
        image3={im8}
        imageTitle1="Agent Bronze"
        imageSousTitle1="Nous avons tout a vous offrir"
        imageTitle2="Agent Silver"
        imageSousTitle2="Nous avons tout a vous offrir"
        imageTitle3="Agent gold"
        imageSousTitle3="Nous avons tout a vous offrir"
      />
      <PreniumServ
        sousTitre1="Services electronique"
        sousTitre3="Nos produits"
        titre1=""
        titre2="Securité Electronique"
      />
      <NewSletter
        sousTitre1=""
        titre1="Vous vouleez en savoir plus sur nos offres "
        titre2="inscrivez vous à notre newsletter"
      />
      
      <Footer />
    </div>
  );
};

function HeroSection() {
  return (
    <div>
      <div
        data-aos="flip-left"
        className="border h-screen flex items-center justify-center rounded-lg p-8"
      >
        <video
          className="clip h-[75%] lg:h-auto w-full rounded-2xl"
          autoPlay
          loop
          muted
        >
          <source type="video/mp4" src={video}/>
        </video>
      </div>
    </div>
  );
}

function BannersHero({ titre1, text1, buttonText1, buttonText2 }) {
  return (
    <div>
      <div data-aos="fade-right">
        <div className=" px-14 flex justify-around items-center h-auto bg-[#FF9900] lg:h-[200px] lg:px-[150px] ">
          <div className="w-[800px] max-sm:hidden dark:text-white">
            <h2 className="text-center text-lg  md:text-2xl capitalize">
              {titre1} <span className="font-bold">{text1}</span>
            </h2>
          </div>
          <div className="max-lg:hidden space-x-8 ">
            <button className="px-6 py-4 bg-[#111047] text-[#FF9900] hover:bg-[#25244e] uppercase font-semibold transition ease-in-out duration-300">
              {buttonText1}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ServP({
  sousTitre1,
  sousTitre3,
  titre1,
  titre2,
  image1,
  image2,
  image3,
  imageTitle1,
  imageTitle2,
  imageTitle3,
  imageSousTitle1,
  imageSousTitle2,
  imageSousTitle3,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const [isModalOpen1, setModalOpen1] = useState(false);
  const toggleModal1 = () => {
    setModalOpen1(!isModalOpen1);
  };
  const [isModalOpen2, setModalOpen2] = useState(false);
  const toggleModal2 = () => {
    setModalOpen2(!isModalOpen2);
  };

  return (
    <div className="h-auto lg:h-auto px-14 md:px-24 max-lg:pb-24">
      <div className="my-8  flex flex-col justify-around">
        <div
          data-aos="fade-up"
          className="flex flex-col items-center gap-8  text-center w-auto"
        ></div>
        <section className="bg-white dark:bg-gray-900 " id="Spp">
          <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div  class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
              <h2 class="mb-4 text-2xl lg:text-3xl uppercase text-secondColor font-extrabold tracking-tight dark:text-white">
                Nos services de sécurité privée
              </h2>
              <p class="mb-5 font-light text-justify text-gray-500 sm:text-xl dark:text-gray-400">
                Nous comprenons que chaque besoin en matière de sécurité est
                unique. C'est pourquoi nous proposons une gamme complète de
                services d'agent de sécurité, chacun adapté pour répondre à des
                exigences spécifiques. Nos offres, classées en trois catégories
                distinctes – Bronze, Silver et Gold – garantissent une solution
                de sécurité sur mesure pour chaque client.
              </p>
            </div>
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent{" "}
                  <span className="text-firstColor text-bold">Bronze</span>
                </h3>
                <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 text-left">
                  Avec les caractéristiques ci-dessous, l’agent Bronze constitue la première catégorie d’agent de sécurité à RADIANT SECURITY
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">
                    NOUS CONSULTER{" "}
                  </span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 space-y-4 lg:h-[400px] text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 55kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m60</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Niveau BEPC et plus</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Formation en sécurité de base</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Bonne capacité d'expression</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Bonne capacité relationnelle</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Parfait exécutant</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Facilité d'intégration</span>
                  </li>
                </ul>
                
              </div>

              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent{" "}
                  <span className="text-firstColor text-bold">Silver</span>
                </h3>

                <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 text-left">
                Avec les caractéristiques ci-dessous, l’agent Silver constitue la deuxième catégorie d’agent de sécurité à RADIANT SECURITY
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">NOUS CONSULTER</span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 lg:h-[400px] space-y-4 text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 60kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m70</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Niveau Bac et plus</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Technique de défense et de sécurité (combat)</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>2 ans d'expérience professionnelle</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Connaissance des techniques de secourisme</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Analyse situationnelle</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Avertir à la gestion des situations délicates</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Bonne capacité organisationnelle</span>
                  </li>
                </ul>
               
              </div>

              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent <span className="text-firstColor text-bold">Gold</span>
                </h3>
                <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400 text-left">
                Avec les caractéristiques ci-dessous, l’agent Gold constitue la Troisième catégorie d’agent de sécurité à RADIANT SECURITY.
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">NOUS CONSULTER</span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 space-y-4 lg:h-[400px] text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 70kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m80</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Niveau Bac+3 et plus</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Formation en service secret</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Bilingue</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Maniement de l'arme à la perfection</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Capacité d'anticipation et de prévention</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Discrets et profilés</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Assistance de première nécessité</span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
function WhatWeDo({ sousTitre2, titre1, titre2, image2, text1, text2 }) {
  const stats = [
    { id: 1, name: "Agents", value: "100+" },
    { id: 2, name: "Partenaires", value: "16+" },
  ];

  return (
    <div className="h-[100%] xl:h-screen grid grid-cols-1 lg:grid-cols-2 py-8 lg:py-12 lg:px-32 place-content-center  gap-x-12">
      <div
        data-aos="zoom-in"
        data-aos-offset="500"
        data-aos-duration="500"
        className="bg-cover py-8 bg-center relative w-[100%] h-screen"
        style={{
          backgroundImage: `url(${image2})`,
          width: "100%",
          height: "500px",
        }}
      >
        <div className=" absolute lg:left-[90px] bottom-8  w-full lg:w-[500px] py-4 sm:py-8 bg-firstColor ">
          <div className="px-2 w-full lg:px-4 gap-8 ">
            <dl className="lg:grid flex w-full gap-x-8 gap-y-16 text-center lg:grid-cols-2">
              {stats.map((stat) => (
                <div
                  key={stat.id}
                  className="mx-auto flex max-w-xs flex-col gap-y-4 gap-x-4"
                >
                  <dt className="order-first text-left leading-7 text-secondColor dark:text-white">
                    {stat.name}
                  </dt>
                  <dd className=" text-3xl font-semibold tracking-tight text-secondColor dark:text-white sm:text-5xl">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-14 md:px-0 gap-y-8 dark:text-white ">
        <h3
          data-aos="fade-up"
          className="text-[#64748b] py-2 text-md uppercase dark:text-white"
        >
          {sousTitre2}
        </h3>
        <h2
          data-aos="fade-right"
          className="text-2xl lg:text-5xl w-auto text-center lg:w-[600px] lg:text-left capitalize "
        >
          {titre1}
          <span className="font-bold">{titre2}</span>
        </h2>
        <p data-aos="fade-left" className="text-justify">
          {text1}{" "}
        </p>
        <p data-aos="fade-left" className="text-justify">
          {text2}{" "}
        </p>
      </div>
    </div>
  );
}
function ServE({
  sousTitre1,
  titre1,
  titre2,
  image1,
  image2,
  image3,
  imageTitle1,
  imageTitle2,
  imageTitle3,
  imageSousTitle1,
  imageSousTitle2,
  imageSousTitle3,
}) {
  return (
    <div className="bg-gray-100 h-auto lg:h-auto px-7 md:px-24 max-lg:pb-24">
      <div className="my-8  flex flex-col justify-around">
        <div
          data-aos="fade-up"
          className="flex flex-col items-center gap-8  text-center w-auto"
        ></div>
        <section class="bg-white dark:bg-gray-900">
          <div id="spa" class="max-w-screen-xl px-2x py-8 mx-auto lg:py-24 lg:px-6">
            <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
              <h2 class="mb-4 text-2xl lg:text-3xl uppercase text-secondColor font-extrabold tracking-tight dark:text-white">
                Nos autres services de sécurité privée
              </h2>
              <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-white text-justify">
                Explorez notre gamme étendue de services spécialisés en
                sécurité, incluant des agents cynophiles, des experts en
                sécurité événementielle et des professionnels de la sécurité
                incendie. Nous vous offrons des solutions adaptées à vos besoins
                spécifiques pour assurer une protection complète et efficace.
              </p>
            </div>
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent de sécurité{" "}
                  <span className="text-firstColor text-bold">Cynophile</span>
                </h3>
                <p class="font-light text-gray-500 sm:text-lg dark:text-white text-left">
                  Découvrez notre service d'agent cynophile, alliant expertise
                  en sécurité et partenariat exceptionnel entre l'homme et le
                  chien pour une protection inégalée.
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">
                  NOUS CONSULTER{" "}
                  </span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 lg:h-[400px] space-y-4 text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 65kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m65</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Niveau BEPC et plus</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      CPACS (Certificat professionnel d'agent cynophile de
                      sécurité)
                    </span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Sang froid</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Gestion de stress et des réactions du chien</span>
                  </li>
                </ul>
               
              </div>

              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent de sécurité{" "}
                  <span className="text-firstColor text-bold">Incendie</span>
                </h3>

                <p class="font-light text-gray-500 sm:text-lg dark:text-white text-left">
                  Explorez notre service d'agent de sécurité incendie, dédié à
                  la protection proactive, à la prévention des risques et à
                  l'intervention efficace en cas d'urgence
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">NOUS CONSULTER</span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 space-y-4 lg:h-[400px] text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 65kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m70</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>BNS (Brevet national de secourisme)</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>SST (secourisme sauveteur du travail)</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>EPI (équipier de premier intervention)</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>ESI (équipier de second intervention)</span>
                  </li>
                </ul>
                
              </div>

              <div class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 class="mb-4 text-2xl font-semibold">
                  Agent de sécurité{" "}
                  <span className="text-firstColor text-bold">
                    évenementiel
                  </span>
                </h3>
                <p class="font-light text-gray-500 sm:text-lg dark:text-white text-left">
                  Découvrez notre équipe d'agents de sécurité événementielle,
                  prête à assurer la sûreté de vos événements avec
                  professionnalisme, vigilance et réactivité
                </p>
                <div class="flex items-baseline justify-center my-8">
                  <span class="mr-2 text-xl lg:text-2xl font-extrabold">NOUS CONSULTER</span>
                  <span class="text-gray-500 dark:text-gray-400">/HT</span>
                </div>

                <ul role="list" class="mb-8 space-y-4 lg:h-[400px] text-left">
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Poids : À partir de 70kg</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Taille : 1m80</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Niveau Bac+3 et plus</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>La protection des biens et des personnes</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Le contrôle des accès</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>La prévention des intrusions et des troubles</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>L’application de procédures de sécurité</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>La gestion des flux de véhicules</span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <svg
                      class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Discrets et profilés</span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
function PreniumServ({ sousTitre1, titre1, titre2, sousTitre3 }) {
  return (
    <div className="h-auto">
      <div className="px-14 md:px-24 lg:px-32" id="spe">
        <div
          data-aos="fade-up"
          id="title1"
          className="py-8 flex flex-col dark:text-white justify-center items-center gap-y-8"
          
        >
          <h3 className="text-[#64748b]  text-md uppercase">{sousTitre1}</h3>
          <h2 className="text-2xl lg:text-5xl w-auto lg:w-[700px] text-center capitalize">
            {titre1}
            <span className="font-bold">{titre2}</span>
          </h2>
          <p class="mb-2 font-light text-gray-500 sm:text-xl dark:text-white text-justify">
          Elles vous donnent accès a une large gamme de produit électronique de dernière génération. Ils allient intelligence artificielle et système stockage robuste et très sécurisé, afin d’offrir une expérience utilisateur unique et agréable. Choisissez RADIANT, Choisissez le meilleur de la sécurité électronique.
          </p>
        </div>
        <section className="py-8" data-aos="flip-left">
          <div class="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex my-10 shadow-teal border-4 border-secondColor">
            <div class="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
              <h3 class="text-2xl uppercase text-left leading-8 font-extraboldc text-gray-900 sm:text-3xl sm:leading-9">
                Offre entreprise
              </h3>
              <p class="mt-6 font-ttnorms leading-8 text-gray-500 text-lg text-justify">
                Les offres entreprises sont conçu pour adresser les réalités des entreprises. Il convient alors pour répondre de manière précise a ces réalités, d’effectuer une analyse minutieuse de nos experts. Une facture proforma sera ensuite soumise avec tous les détails. Cependant vous pourriez y voir les élément suivants :
              </p>
              <div class="mt-8">
                <div class="flex items-center">
                  <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-firstColor">
                   Nous proposons :
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <ul class="pl-0 mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 space-y-5 lg:space-y-0">
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Systèmes de Surveillance par Caméra (CCTV) 
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Systèmes d'Alarme Intrusion
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Contrôle d'Accès Électronique
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Surveillance à Distance
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Formation du Personnel
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Maintenance et Support Techniquee
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Personnalisation et Intégration
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Rapports de Sécurité
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
              <p class="text-xl leading-6 font-medium text-gray-900 lg:max-w-xs lg:mx-auto mb-0 lg:mb-6">
               Tarification
              </p>
              <div class="my-10 lg:my-6 flex items-baseline justify-center text-5xl leading-none font-extrabold text-gray-900">
                <span class="font-brown"></span>
                <span class="text-xl leading-7 font-medium text-gray-500 font-ttnorms">
                  Veuillez contacter l'équipe Technique
                </span>
              </div>
              <div class="lg:mt-6">
                <div class="rounded-md shadow">
                  <a
                    href="/About#form"
                    class="flex items-center justify-center px-5 py-3 leading-6 font-medium rounded-md focus:outline-none focus:ring transition duration-200 ease-in-out shadow-teal border-2 border-firstColor bg-white hover:bg-firstColor hover:shadow-teal-hover text-firstColor hover:text-white text-lg relative z-20"
                  >
                    Nous contacter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-8" data-aos="flip-right">
          <div class="max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex my-10 shadow-teal border-4 border-secondColor">
            <div class="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
              <h3 class="text-2xl uppercase text-left leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                Offre résidentielle
              </h3>
              <p class="mt-6 text-left font-ttnorms leading-8 text-gray-500 text-lg">
                l'offre résidentielle est destinée aux résidences. Les tarifs sont fonctions de attentes spécifiques du client. Voici quelques suggestions :
              </p>
              <div class="mt-8">
                <div class="flex items-center">
                  <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-firstColor">
                   Nous proposons :
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <ul class="pl-0 mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 space-y-5 lg:space-y-0">
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Devis Personnalisé Gratuit  
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Consultation de Sécurité Gratuite
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Tarification sur Mesure
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Évaluation Gratuite des Besoins en Sécurité
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Solution de Sécurité Adaptée
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Personnalisation de la Tarification
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Forfait Sécurité Sur Mesure
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Tarification Flexible selon les Besoin
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-firstColor"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p class="ml-3 text-lg leading-5 text-gray-700 font-ttnorms text-left">
                      Analyse Gratuite de Sécurité
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
              <p class="text-xl leading-6 font-medium text-gray-900 lg:max-w-xs lg:mx-auto mb-0 lg:mb-6">
               Tarification
              </p>
              <div class="my-10 lg:my-6 flex items-baseline justify-center text-5xl leading-none font-extrabold text-gray-900">
                <span class="font-brown"></span>
                <span class="text-xl leading-7 font-medium text-gray-500 font-ttnorms">
                  Veuillez contacter l'équipe Technique
                </span>
              </div>
              <div class="lg:mt-6">
                <div class="rounded-md shadow">
                  <a
                    href="/About#form"
                    class="flex items-center justify-center px-5 py-3 leading-6 font-medium rounded-md focus:outline-none focus:ring transition duration-200 ease-in-out shadow-teal border-2 border-firstColor bg-white hover:bg-firstColor hover:shadow-teal-hover text-firstColor hover:text-white text-lg relative z-20"
                  >
                    Nous contacter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3 className="text-[#64748b] dark:text-white text-center text-[12px] uppercase">{sousTitre3}</h3>
        <div className="py-4 grid grid-cols-1 px-4 lg:px-24 gap-4 md:grid-cols-2 lg:grid-cols-4 ">
          <div
            data-aos="fade-left-up"
            data-aos-offset="200"
            data-aos-duration="200"
            className="bg-white shadow-2xl text-center flex flex-col items-center dark:text-secondColor gap-y-2 py-4"
          >
            <div className="p-8">
              <img src={product1} className="h-full w-full" />
            </div>
            <h2 className="font-bold text-3xl capitalize ">camera thermique</h2>
            <p>la caméra thermique SEEK Reveal Fire Pro X  </p>
            <PiDotsThreeOutlineFill className=" font-bold text-secondColor/50 text-4xl" />
          </div>
          <div
            data-aos="fade-left-up"
            data-aos-offset="200"
            data-aos-duration="200"
            className="bg-white shadow-2xl text-center flex flex-col items-center dark:text-secondColor gap-y-2 py-4"
          >
            <div className="p-8">
              <img className="h-full w-full " src={product8} />
            </div>
            <h2 className="font-bold text-3xl capitalize ">Detecteur</h2>
            <p className="lowercase">FIBRA DOORPROTECT AJAX - CONTACT MAGNÉTIQUE ET DÉTECTEUR DE CHOC PORTE OU FENÊTRE NOIR - DÉTECTEUR INTÉRIEUR</p>
            <PiDotsThreeOutlineFill className=" font-bold text-secondColor text-4xl" />
          </div>
          <div
            data-aos="fade-left-up"
            data-aos-offset="200"
            data-aos-duration="200"
            className="bg-white shadow-2xl text-center flex flex-col items-center dark:text-secondColor gap-y-2 py-4"
          >
            <div className="p-8">
              <img className="h-full w-full " src={product2} />
            </div>
            <h2 className="font-bold text-3xl capitalize ">Talkiwalki</h2>
            <p>Portatif radio MIDLAND - G10 PRO </p>
            <PiDotsThreeOutlineFill className=" font-bold text-secondColor text-4xl" />
          </div>
          <div
            data-aos="fade-left-up"
            data-aos-offset="200"
            data-aos-duration="200"
            className="bg-white shadow-2xl text-center flex flex-col dark:text-secondColor items-center gap-y-2 py-4"
          >
            <div className="p-8">
            <img className="h-full w-full " src={product4} />
            </div>
            <h2 className="font-bold text-3xl capitalize ">Talkiwalki</h2>
            <p>OREILLETTE CONTOUR D'OREILLE POUR G15 / G18</p>
            <PiDotsThreeOutlineFill className=" font-bold text-secondColor text-4xl" />
          </div>
        </div>
        <div className="flex justify-center items-center my-8 ">
          <a className="m-8 lg:m-16 relative group cursor-pointer">
           <Link to="" target="_blank">
            <a className="" href="/Products">Voir nos autres produits</a>
           </Link>
            <span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-firstColor duration-300 ease-in-out group-hover:w-1/2 group-hover:transition-all"></span>
            <span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-firstColor duration-300 ease-in-out group-hover:w-1/2 group-hover:transition-all"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

function NewSletter({ sousTitre1, titre1, titre2 }) {
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    // Logique d'inscription à la newsletter
    console.log(`Inscription à la newsletter avec l'email : ${email}`);
    // Vous pouvez envoyer l'email au backend pour le traitement ici
  };

  return (
    <div className="">
      <div>
        <div className="relative isolate overflow-hidden bg-white  py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 px-14 md:px-24 gap-y-16 lg:max-w-none lg:grid-cols-2">
              <div className="max-w-xl lg:max-w-lg dark:text-black">
                <h2 className="text-2xl md:text-3xl lg:text-5xl w-auto lg:w-[500px] text-left sm:text-4xl capitalize">
                  {titre1}
                  <span className="font-bold">{titre2}</span>
                </h2>
                <div className="mt-6 flex max-w-md gap-x-4">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    placeholder="Entrez votre adresse e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}            
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 flex-auto rounded-md border-0 bg-firstColor/25 px-3.5 py-2 text-secondColor shadow-sm ring-1 ring-inset ring-firstColor/40 focus:ring-2 focus:ring-inset focus:ring-firstColor sm:text-sm sm:leading-6"
                  />
                  <button
                    onClick={handleSubscribe}
                    type="submit"
                    className="flex-none rounded-md bg-firstColor px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition ease-in duration-200 hover:bg-firstColor/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-firstColor"
                  >
                    s'inscrire
                  </button>
                </div>
              </div>
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <CalendarDaysIcon
                      className="h-6 w-6 text-firstColor"
                      aria-hidden="true"
                    />
                  </div>
                  <dt className="mt-4 font-semibold text-secondColor">
                    Articles de la semaines
                  </dt>
                  <dd className="mt-2 leading-7 text-secondColor/70">
                    Restez informé sur les événements à venir que nous couvrons pour assurer la sécurité de nos clients. De grands rassemblements aux occasions spéciales, nous veillons à votre tranquillité d'esprit.
                  </dd>
                </div>
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <HandRaisedIcon
                      className="h-6 w-6 text-firstColor"
                      aria-hidden="true"
                    />
                  </div>
                  <dt className="mt-4 font-semibold text-secondColor">
                    Conseils de sécurité
                  </dt>
                  <dd className="mt-2 leading-7 text-secondColor/70">
                    Des conseils pratiques pour renforcer la sécurité dans votre entreprise. Apprenez les meilleures pratiques et les dernières stratégies pour garantir un environnement sûr.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div
            className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-firstColor/60 to-firstColor opacity-30"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function BannersFeat({ titre1, text1, buttonText1, buttonText2, image3 }) {
  return (
    <div>
      <div className="bannerHero">
        <div class="container mx-auto">
          <div class="-m-1 flex flex-wrap lg:h-[1000px] md:m-0">
            <div
              data-aos="zoom-in-left"
              data-aos-offset="500"
              data-aos-duration="500"
              class="flex w-1/2 h-4/4 flex-wrap"
            >
              <div class="h-full cursor-pointer relative overflow-hidden bg-cover2">
                <img
                  data-popover-target="popover-description"
                  alt="gallery"
                  class="block h-full w-full  object-cover object-center transition duration-300 ease-in-out hover:scale-110"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                />
                <div
                  data-popover
                  id="popover-description"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                >
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"></div>
                  <div class="px-3 py-2">
                    <p>
                      And here's some amazing content. It's very engaging.
                      Right?
                    </p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
            </div>
            <div
              data-aos="zoom-in-right"
              data-aos-offset="500"
              data-aos-duration="500"
              class="flex w-1/2 flex-wrap"
            >
              <div
                data-popover-target="popover-default"
                class=" w-1/2 cursor-pointer relative overflow-hidden bg-cover"
              >
                <img
                  alt="gallery"
                  class="block h-full w-full  object-cover object-center transition duration-300 ease-in-out hover:scale-110"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
                />
                <div
                  data-popover
                  id="popover-default"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                >
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"></div>
                  <div class="px-3 py-2">
                    <p>
                      And here's some amazing content. It's very engaging.
                      Right?
                    </p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div
                data-popover-target="popover-default"
                class=" w-1/2 cursor-pointer relative overflow-hidden bg-cover"
              >
                <img
                  alt="gallery"
                  class="block h-full w-full  object-cover object-center transition duration-300 ease-in-out hover:scale-110"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
                />
                <div
                  data-popover
                  id="popover-default"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                >
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"></div>
                  <div class="px-3 py-2">
                    <p>
                      And here's some amazing content. It's very engaging.
                      Right?
                    </p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div
                data-popover-target="popover-default"
                class=" w-1/2 cursor-pointer relative overflow-hidden bg-cover"
              >
                <img
                  alt="gallery"
                  class="block h-full w-full  object-cover object-center transition duration-300 ease-in-out hover:scale-110"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
                />
                <div
                  data-popover
                  id="popover-default"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                >
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"></div>
                  <div class="px-3 py-2">
                    <p>
                      And here's some amazing content. It's very engaging.
                      Right?
                    </p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div
                data-popover-target="popover-default"
                class=" w-1/2 cursor-pointer relative overflow-hidden bg-cover"
              >
                <img
                  alt="gallery"
                  class="block h-full w-full  object-cover object-center transition duration-300 ease-in-out hover:scale-110"
                  src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
                />
                <div
                  data-popover
                  id="popover-default"
                  role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
                >
                  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700"></div>
                  <div class="px-3 py-2">
                    <p>
                      And here's some amazing content. It's very engaging.
                      Right?
                    </p>
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-aos="fade-right">
            <div className="px-14 flex justify-around items-center h-[100px] bg-[#FF9900] lg:h-[200px] lg:px-[150px] ">
              <div className="lg:w-[90%]">
                <h2 className="text-center text-lg  md:text-2xl lg:text-3xl capitalize">
                  {titre1} <span className="font-bold">{text1}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
