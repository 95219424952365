import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logosvg } from "../../constants/images";
import NavLinks from "./NavLinks";
import DarkButton from "./darkButton";

import './Navbar.css'
import { FaMoon } from "react-icons/fa6";



 



const Navbar = () => {

  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-white  dark:text-black z-50">
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-5 md:w-auto w-full flex justify-between">
         <Link to="/">
          <img src={Logosvg} alt="logo" className="md:cursor-pointer h-12 lg:h-24 " />
         </Link>
          <div className="text-4xl md:hidden " onClick={() => setOpen(!open)}>
            <ion-icon  name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-4 lg:gap-8 font-bold ">
          <li>
            {/*<Link to="/Products" className="px-2 inline-block hover-underline-animation">
             Produits
  </Link>*/}
          </li>
          <li>
            <Link to="/Services" className="px-2 inline-block hover-underline-animation">
             Services
            </Link>
          </li>
          <li>
            <Link to="/About" className="px-2 inline-block hover-underline-animation">
             A propos
            </Link>
          </li>
          <li className="hidden">
            <DarkButton/>
          </li>
        </ul>
        

        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white text-secondColor fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 z-40 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link to="/" className="my-7 px-3 inline-block hover-underline-animation">
              Acceuil
            </Link>
          </li>
            <li>
              <Link to="/Products" className="my-7 px-3 inline-block hover-underline-animation">
                Produits
              </Link>
            </li>
            <li>
              <Link to="/Services" className="my-7 px-3 inline-block hover-underline-animation">
                Services
              </Link>
            </li>
          <li>
            <Link to="/About" className="my-7 px-3 inline-block hover-underline-animation">
              A propos
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;