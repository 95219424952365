import React from "react"
import { Footer, Navbar } from "./components";
import DOMPurify from 'dompurify';
import { product1, product2, product3, product4, product5, product6, product7, product8, product9, product10, product11, product12, product13, product14 } from "./constants/images";

function Products() {
  const Comment = ({ text }) => {
    // Utilisez DOMPurify pour nettoyer le contenu HTML
    const sanitizedHTML = DOMPurify.sanitize(text);
  
    return (
      <div>
        {/* Contenu nettoyé */}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    );
  };

  const data = [
    
    { id: 1, imageUrl:product1, alt:"produit", name:"camera à infrarouge", catName:"Camera", price:""},
    { id: 2, imageUrl:product2, alt:"produit", name:"Portatif radio MIDLAND - G10 PRO", catName:"Talkiwalki", price:""},
    { id: 3, imageUrl:product3, alt:"produit", name:"Portatif radio MIDLAND - G10 PRO", catName:"Talkiwalki", price:""},
    { id: 4, imageUrl:product4, alt:"produit", name:"Portatif radio MIDLAND - G10 PRO", catName:"Talkiwalki", price:""},
    { id: 5, imageUrl:product5, alt:"produit", name:"OREILLETTE CONTOUR D'OREILLE POUR G15 / G18", catName:"Talkiwalki", price:""},
    { id: 6, imageUrl:product6, alt:"produit", name:"OREILLETTE CONTOUR D'OREILLE POUR G15 / G18", catName:"Talkiwalki", price:""},
    { id: 7, imageUrl:product7, alt:"produit", name:"CONTACT MAGNÉTIQUE BLANC SANS FIL KSENIA POLI - 2 ENTRÉES AUXILIAIRES PROGRAMMABLES - BIDIRECTIONNEL", catName:"Detecteur", price:""},
    { id: 8, imageUrl:product8, alt:"produit", name:"FIBRA DOORPROTECT AJAX - CONTACT MAGNÉTIQUE ET DÉTECTEUR DE CHOC PORTE OU FENÊTRE NOIR - DÉTECTEUR INTÉRIEUR", catName:"Detecteur", price:""},
    { id: 9, imageUrl:product9, alt:"produit", name:"Konyks Camini Care", catName:"Caméra", price:""},
    { id: 10, imageUrl:product10, alt:"produit", name:"Tapo Caméra Surveillance WiFi intérieure 1080P C200, détection de personne", catName:"Caméra", price:""},
    { id: 11, imageUrl:product11, alt:"produit", name:"eufy Security eufyCam 2C", catName:"Camera", price:""},
    { id: 12, imageUrl:product12, alt:"produit", name:"CLAVIER À CODES ANTIVANDALE WIEGAND ET DATA CLOCK", catName:"Contrôle d'accès", price:""},
    { id: 13, imageUrl:product13, alt:"produit", name:"DÉTECTEUR VOLUMÉTRIQUE DOUBLE", catName:"Detecteur", price:""},
    { id: 14, imageUrl:product14, alt:"produit", name:"ARC-A - LECTEUR STID ARCHITECT®", catName:"Contrôle d'accès", price:""},
  ]
  return (
    <div id="pagesProduct">
      <Comment />
      <Navbar />

      <section data-aos="zoom-in"
        id="Projects"
        class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5"
      >
        {data.map(item => (
          <div key={item.id} class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
            <a href="#">
              <img
                src={item.imageUrl}
                alt="Product"
                class="h-80 w-72 object-cover rounded-t-xl loading-lazy"
              />
              <div class="px-4 py-3 w-72">
                <span class="text-gray-400 mr-3 uppercase text-xs">{item.catName}</span>
                <p class="text-lg font-bold text-black truncate block capitalize">
                  {item.name}
                </p>
                <div class="flex items-center">
                  <p class="text-lg font-semibold text-black cursor-auto my-3">
                    {item.price}
                  </p>
                </div>
              </div>
            </a>
          </div>
         ))}
      </section>
      <Footer />
    </div>
  );
}

export default Products;
