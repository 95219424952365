import React from 'react'
import {  } from '../../constants/images'
import Loader from '../../assets/images/Loader_XL.gif'


function Loadingpage() {
  return (
    <div className='loading-container h-screen sm:h-1/2 lg:h-1/3 xl:h-1/4  flex bg-[#333333] items-center justify-center '>
        <img src={Loader} className='object-cover h-screen  ' alt='loader'/>
    </div>
  )
}

export default Loadingpage